import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

import { ViewProvider } from "./context/AppContext"
import Home from './view-components/Home'

import "./styles/index.css";
import "./styles/palette.css";
import "./styles/fonts.css";
import "./styles/elements.css";

ReactDOM.render(
  <React.StrictMode>
    <ViewProvider>
      <Home />
    </ViewProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from 'react'

export const Logo = () => {
  return (
    <picture className="logo">
      {/* <img
        width="151"
        height="27"
        sizes="(max-width: 1400px) 100vw, 1400px"
        srcSet="
/OPOLIS_Text_WHITE_Large_smi4oy_c_scale,w_200.png 200w,
/OPOLIS_Text_WHITE_Large_smi4oy_c_scale,w_1400.png 1400w"
        src="/OPOLIS_Text_WHITE_Large_smi4oy_c_scale,w_1400.png"
        alt="Opolis logo"
      /> */}
    </picture>
  )
}